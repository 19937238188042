import React from "react";

import "./index.css";

const BlogCard = ({
  title,
  shortDesc,
  author,
  thumbnail,
  url,
  containerStyle,
  thumbNailStyle
}) => {
  return (
    <div
      className="blog-card-container"
      onClick={() => {
        window.open(url, "_blank");
      }}
      style={containerStyle}
    >
      <div className="blog-card-meta">
        <img
          src={thumbnail}
          alt="/blog/default-img.svg"
          className="blog-card-thumbnail"
          style={thumbNailStyle}
        />
      </div>
      <div className="blog-card-content">
        <div className="blog-card-title">{title}</div>
        <div className="blog-card-desc">{shortDesc}</div>
        <div className="blog-card-divider" />
        <div className="blog-card-author">{author}</div>
      </div>
    </div>
  );
};

export default BlogCard;
