import React from "react";
import BlogCard from "../BlogCard";

import "./index.css";

import blogs from "../../blogs";

import { isMobile, browserName } from "react-device-detect";
import Slider from "react-slick";

const slickSetting = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  variableWidth: true,

  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        autoplay: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        autoplay: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        autoplay: true,
      },
    },
  ],
};

const HomeBlog = () => {
  return (
    <div className="homeblog-container" id="blog">
      <div
        className={
          browserName === "Chrome" ? "homeblog-title-chrome" : "homeblog-title"
        }
      >
        Blog
      </div>
      {!isMobile && (
        <div className="homeblog-list">
          {blogs.map((blog) => (
            <BlogCard {...blog} />
          ))}
        </div>
      )}
      {isMobile && (
        <Slider
          {...slickSetting}
          className="inthepress-slider"
          style={{ marginBottom: "64px" }}
        >
          {blogs.map((blog) => (
            <BlogCard
              {...blog}
              containerStyle={{ marginRight: "24px", width: "320px" }}
            />
          ))}
        </Slider>
      )}
      <a className="homeblog-readmore" href="/blogs">
        Read more
      </a>
    </div>
  );
};

export default HomeBlog;
