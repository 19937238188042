// eslint-disable-next-line import/no-anonymous-default-export
export default {
  title: "EDDASwap and Polygon announce partnership",
  author: "EDDASwap",
  url: "/blog/eddaswap-polygon",
  key: "eddaswap-polygon",
  date: "April 19th, 2021",
  thumbnail:
    "https://edda-cdn.fra1.digitaloceanspaces.com/eddaswap/blog/polygon.png",
  content: (
    <>
      <p style={{ marginBottom: "20px" }}>
        EDDASwap has officially partnered with Polygon (formerly Matic Network),
        and will implement Polygon’s industry-leading L2 scaling technology to
        bring unmatched speed and scalability to EDDSwap’s ecosystem of DeFi and
        NFT applications.
      </p>
      <img
        src="https://edda-cdn.fra1.digitaloceanspaces.com/eddaswap/blog/polygon.png"
        alt=""
        style={{ width: "100%", marginBottom: "20px" }}
      />
      <p style={{ marginBottom: "20px" }}>
        EDDASwap’s NFT platform, which gives users access to exclusive NFTs
        created by the world’s leading 3D artists and motion graphic designers
        like Primal Cypher, OZ, Space Yacht Labs, Stefan Große Halbuer, Cyrus
        James Khan, Layerinsane, Alphie and many more is already live. However,
        to scale the upcoming platforms, including the flagship DEX, EDDASwap
        will build on Polygon.
      </p>
      <p style={{ marginBottom: "80px" }}>
        “At EDDASwap, we believe user experience is the most fundamental
        building block for the platform’s success. A platform where each
        transaction costs double or sometimes triple digits in gas fees and
        often takes several minutes, is simply not good enough. With Polygon’s
        L2 scaling solutions, we’ll be able to offer our users ultra-fast
        transactions with almost negligible fees. This is a crucial first step
        in realizing our ambition of creating an ecosystem that will be used by
        millions of users worldwide” said Mehul (Mike) Patel, CEO of EDDASwap.
      </p>
      <p className="blog-content-heading">
        Bringing decentralized trading to the masses
      </p>
      <p style={{ marginBottom: "20px" }}>
        The flagship application of EDDASwap’s ecosystem, the{" "}
        <a
          href="https://dex.eddaswap.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "700" }}
        >
          DEX
        </a>
        , is the first platform that EDDASwap will build on Polygon which will
        launch in Q2 this year. Powered by Polygon’s scaling capabilities,
        transactions on the{" "}
        <a
          href="https://dex.eddaswap.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "700" }}
        >
          EDDASwap DEX
        </a>{" "}
        will incur almost no gas fees (less than $0.0001 per transaction), and
        transaction finality will be lightning-fast.
      </p>
      <p style={{ marginBottom: "20px" }}>
        With other major DEXs in the industry struggling with high fees, and
        delaying their L2 implementations, the EDDASwap DEX will have a huge
        competitive edge, by offering unparalleled scaling without compromising
        on decentralization.
      </p>
      <p style={{ marginBottom: "80px" }}>
        To bootstrap liquidity on the EDDASwap DEX, Polygon will run a liquidity
        mining campaign. Additionally, EDDASwap will incentivize liquidity
        providers through incentive programs in the initial stages after the
        DEX’s launch.
      </p>
      <p className="blog-content-heading">
        Unlocking the maximum potential of NFTs
      </p>
      <p style={{ marginBottom: "20px" }}>
        Artists and art collectors exploring the NFT space are severely limited
        by high gas fees, making the simple act of minting or buying an NFT a
        needlessly expensive activity, often costing double or even triple
        digits in dollars.
      </p>
      <p style={{ marginBottom: "20px" }}>
        To solve this, and make NFTs universally accessible and affordable for
        everyone, EDDASwap is expanding their NFT platform by building on
        Polygon. In EDDASwap’s upgraded{" "}
        <a
          href="https://app.eddaswap.com/nfts"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "700" }}
        >
          NFT platform
        </a>
        , the process of minting and transacting with NFTs will be exponentially
        cheaper and faster.
      </p>
      <p style={{ marginBottom: "80px" }}>
        V2 of EDDASwap’s NFT platform is currently under development, and will
        bring entirely new primitives to the NFT industry, including the merging
        of DeFi with NFTs. EDDASwap’s proven success in the NFT space, combined
        with Polygon’s scaling technology, will take NFTs to the next level.
      </p>
      <p className="blog-content-heading">About EDDASwap</p>
      <p style={{ marginBottom: "80px" }}>
        8. However, those users that wish to receive the actual physical coins
        will have the option of claiming their physical coins by burning the
        NFT. These users will then be prompted to provide their delivery
        address, and the physical coin will be shipped to them by EDDASwap for
        free. Each coin is loaded with 0.02 ETH as well!
      </p>
      <p className="blog-content-heading">More about the Silver Coins</p>
      <p style={{ marginBottom: "20px" }}>
        EDDASwap is an ecosystem of NFT and DeFi platforms. EDDASwap’s flagship
        multi-chain DEX will let the users trade crypto assets on leading
        blockchains such as Ethereum, Polygon, Binance Smart Chain, and
        Polkadot.
      </p>
      <p style={{ marginBottom: "20px" }}>
        The NFT Platform gives users access to exclusive high quality NFTs
        created by the world’s leading motion graphic designers, 3D and digital
        artists.
      </p>
      <p style={{ marginBottom: "20px" }}>
        The Decentralized Launchpad provides users the opportunity to invest in
        exciting new crypto projects before they launch and are publicly
        available.
      </p>
      <p style={{ marginBottom: "80px" }}>
        At the core of the EDDASwap ecosystem is the EDDA token, with a fixed
        supply of only 5000 tokens forever.
      </p>
      <p className="blog-content-heading">About Polygon (Formerly Matic)</p>
      <p style={{ marginBottom: "20px" }}>
        Polygon is the first well-structured, easy-to-use platform for Ethereum
        scaling and infrastructure development. Its core component is Polygon
        SDK, a modular, flexible framework that supports building and connecting
        Secured Chains like Plasma, Optimistic Rollups, zkRollups, Validium etc
        and Standalone Chains like Matic POS, designed for flexibility and
        independence. Polygon’s Layer 2 Chains have seen widespread adoption
        with 130+ Dapps, ~19M txns and ~330K unique users.
      </p>
      <p style={{ marginBottom: "20px" }}>
        If you're an Ethereum Developer, you're already a Polygon developer!
      </p>
    </>
  ),
  shortDesc:
    "EDDASwap has officially partnered with Polygon (formerly Matic Network), and will implement Polygon’s industry-leading L2 scaling technology to bring unmatched speed and scalability to EDDSwap’s ecosystem of DeFi and NFT applications.",
};
