import MetaTags from "react-meta-tags";

export default {
  title: "A look-back at EDDASwap’s first year",
  author: "EDDASwap",
  url: "/blog/eddaswap-lookback",
  key: "eddaswap-lookback",
  date: "May 2nd, 2022",
  thumbnail: "/logo.svg",
  thumbNailStyle: {
    objectFit: "contain",
    background: "ghostwhite",
  },
  shortDesc:
    "Join us as we commemorate EDDASwap's one-year anniversary, highlighting our strides in NFTs, DeFi, metaverse, and multi-chain ecosystems. Discover our success stories — from exclusive NFTs and physical collectibles to our innovative multi-chain DEX and exciting new IPs, as we continue to redefine the blockchain space.",
  content: (
    <>
      <MetaTags>
        <meta
          property="og:description"
          content="Join us as we commemorate EDDASwap's one-year anniversary, highlighting our strides in NFTs, DeFi, metaverse, and multi-chain ecosystems. Discover our success stories — from exclusive NFTs and physical collectibles to our innovative multi-chain DEX and exciting new IPs, as we continue to redefine the blockchain space."
        />
        <meta
          name="description"
          content="Join us as we commemorate EDDASwap's one-year anniversary, highlighting our strides in NFTs, DeFi, metaverse, and multi-chain ecosystems. Discover our success stories — from exclusive NFTs and physical collectibles to our innovative multi-chain DEX and exciting new IPs, as we continue to redefine the blockchain space."
        />
      </MetaTags>
      <p style={{ marginBottom: "20px" }}>Dear EDDASwap community,</p>
      <p style={{ marginBottom: "20px" }}>
        It has been slightly longer than a year since EDDASwap launched, and it
        goes without saying, it has been an incredible journey! First and
        foremost, the entire EDDASwap team would like to take a moment to deeply
        thank the whole community for the unwavering support you have shown
        throughout the year. We are truly blessed to have every single one of
        you.
      </p>
      <p style={{ marginBottom: "20px" }}>
        During just our first year, the EDDASwap ecosystem has already expanded
        to several different verticals including NFTs, DeFi, the metaverse,
        real-world luxury collectibles and a lot more, through all the different
        platforms encompassing the multi-chain ecosystem that we have built. And
        the industry has most certainly taken notice, as EDDASwap has received
        widespread{" "}
        <a
          href="https://eddaswap.com/#inthepress"
          target="_blank"
          rel="noopener noreferrer"
        >
          media coverage
        </a>{" "}
        globally from Forbes, Bloomberg, Yahoo, and other leading publications.
      </p>
      <p style={{ marginBottom: "20px" }}>
        As EDDASwap’s first year comes to an end, we thought it would be a good
        opportunity to create a look-back at the year and everything EDDASwap
        achieved over its course, to serve as a comprehensive primer for our
        community members, both old and new.
      </p>
      <p className="blog-content-heading-link">
        <a
          href="https://app.eddaswap.com/nfts"
          target="_blank"
          rel="noopener noreferrer"
        >
          EDDASwap’s NFT Platform
        </a>
      </p>
      <p style={{ marginBottom: "20px" }}>
        Unsurprisingly, our much-loved NFT platform has been the most successful
        and widely used platform in the entire EDDASwap ecosystem. EDDA token
        holders have been staking their tokens to earn exclusive,
        limited-edition NFTs specially created for EDDASwap by the world’s
        leading 3D artists and motion graphic designers, including legends such
        as Primal Cypher, OZ, Space Yacht Labs, Stefan Große Halbuer, Cyrus
        James Khan, Layerinsane, Alphie, and many more.
      </p>
      <p style={{ marginBottom: "20px" }}>
        On NFT marketplaces like{" "}
        <a
          href="https://opensea.io/collection/eddaswap"
          target="_blank"
          rel="noopener noreferrer"
        >
          Opeansea
        </a>
        , top quality NFTs by such leading artists have been commanding
        extremely high valuations. As an example, EDDASwap’s 360° VR NFTs
        redeemed by EDDA stakers have collectively sold for over 50 ETH. And the
        best part is, EDDA token holders have been getting all of these NFTs
        completely for free!
      </p>
      <p style={{ marginBottom: "20px" }}>
        We’ve also expanded the platform to a multi-chain NFT marketplace,
        allowing users to purchase the NFTs using a wide variety of leading
        cryptocurrencies. We were one of the first platforms in the world to
        enable the APE token as a payment mode, and we’ve done the same for
        several other tokens by popular demand, including LOOKS, SHIB, SOS, DAI,
        USDT, USDC, and over a dozen others.
      </p>
      <p className="blog-content-heading">EDDASwap’s NFTs</p>
      <p style={{ marginBottom: "20px" }}>
        We have created and minted over tens of thousands of NFTs across
        blockchains like Ethereum, Polygon, Solana, Binance Smart Chain, and
        Terra Luna. And our NFTs have gone viral globally and have been shared,
        retweeted, and posted by{" "}
        <a
          href="https://www.instagram.com/p/CKH7ArRjyVw/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cameron and Tyler Winklevoss
        </a>
        , Jason Williams, and many more leading influencers. The following are
        some of our most iconic NFTs created over the past year:
      </p>
      <ul>
        <li>
          <a
            href="https://youtu.be/T4A_9rnMC2c"
            target="_blank"
            rel="noopener noreferrer"
          >
            I am a MadTrooper
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=xTfflnM2nDU"
            target="_blank"
            rel="noopener noreferrer"
          >
            MadTrooper and Eva
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=YSe3hFQg4vA"
            target="_blank"
            rel="noopener noreferrer"
          >
            Planet Niflheim 
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=GpDRBW5y1wQ&t=1s"
            target="_blank"
            rel="noopener noreferrer"
          >
            Voyage to EDDA
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=_AFgmuVVGv8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Astro Adventures
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=Aj7EtWskdlQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            EDDA Universe
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=VqEyp48Kz5w"
            target="_blank"
            rel="noopener noreferrer"
          >
            When Two Minds Click
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=RYT53en79xc"
            target="_blank"
            rel="noopener noreferrer"
          >
            Afternoon 
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=reWxwsS0WBc"
            target="_blank"
            rel="noopener noreferrer"
          >
            Golden Peacock 
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=adIoISQaUyI"
            target="_blank"
            rel="noopener noreferrer"
          >
            SwapTrooper Holotraining
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=LQnV22lfhwM"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Time Gap Continuum
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=0Zqw4uXwoDo"
            target="_blank"
            rel="noopener noreferrer"
          >
            7 Wonders of the World - Christ The Redeemer
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=T8agz47769k"
            target="_blank"
            rel="noopener noreferrer"
          >
            Vitalik Buterin
          </a>
        </li>
        <li>
          <a
            href="https://youtu.be/rSBGgdMJI6M"
            target="_blank"
            rel="noopener noreferrer"
          >
            MadTrooper Rising
          </a>
        </li>
      </ul>
      <p className="blog-content-heading-link">
        <a
          href="https://app.eddaswap.com/ag"
          target="_blank"
          rel="noopener noreferrer"
        >
          EDDASwap’s Staking Platform 2.0 – Staking for Physical Collectibles!
        </a>
      </p>
      <p style={{ marginBottom: "20px" }}>
        EDDASwap’s Staking Platform has been revamped with a never before seen
        twist: staking for physical collectibles! The “EDDASwap” and “ Mahatma
        Gandhi” pure silver coins are just the first of many ultra high-end,
        luxury collectibles that will be made available to stakers through the
        new platform.
      </p>
      <p style={{ marginBottom: "20px" }}>
        The coins are made of pure silver, and both are limited edition designs.
        And in true EDDASwap style, beyond the luxurious exterior and design,
        there is also unique functionality, because the coins are also physical
        ETH wallets with 0.02 ETH pre-loaded with as a bonus!
      </p>
      {/* <p className="blog-content-heading-link">
        <a
          href="https://dex.eddaswap.com/exchange"
          target="_blank"
          rel="noopener noreferrer"
        >
          EDDASwap’s Multi-Chain DEX
        </a>
      </p>
      <p style={{ marginBottom: "20px" }}>
        The multi-chain DEX is another major platform we launched this year, and
        trading is supported on Binance Smart Chain and Polygon. We{" "}
        <a
          href="https://twitter.com/0xPolygon/status/1384051633840029698"
          target="_blank"
          rel="noopener noreferrer"
        >
          partnered with Polygon
        </a>{" "}
        and implemented their L2 scaling technology to bring unmatched speed and
        scalability to the EDDASwap Polygon DEX. Transactions on the DEX incur
        almost no gas fees (less than $0.0001 per transaction), and transaction
        finality is lightning-fast.
      </p> */}
      {/* <p style={{ marginBottom: "20px" }}>
        We have maintained a core focus on security for the DEX and implemented
        “Liquidity Lock”, an optional feature whereby anyone that lists their
        token onto the DEX has the ability to lock in the liquidity for the
        token for a designated period of time. Every project that wishes to
        legitimise themselves can make use of this feature to create trust, and
        provide token buyers some much needed security.
      </p>
      <p style={{ marginBottom: "20px" }}>
        There are a few surprises in place for LPs too, as in addition to the
        trading fees that accrue to LPs, we have implemented a special NFT-based
        reward mechanism for all LPs. All LPs on the DEX can stake their
        respective LP tokens to receive EDDASwap’s exclusive NFTs. Therefore,
        LPs on EDDASwap’s DEX are generously incentivized, perpetually receiving
        a passive stream of rewards including both trading fees and exclusive
        NFTs.
      </p> */}
      <p className="blog-content-heading-link">
        <a
          href="https://madtrooper.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          MadTrooper
        </a>
      </p>
      <p style={{ marginBottom: "20px" }}>
        MadTrooper is a flagship IP from EDDASwap, with an NFT collection and
        blockchain-based game, which we recently launched on Solana. Each
        MadTrooper has been algorithmically generated to be completely unique,
        with individualized traits and attributes. The art has varied themes
        such as CyberPunk, Hippie, and Rock n Roll. Additionally, the MadTrooper
        collection has an elaborately designed rarity system, with a few
        ultra-rare NFTs of legendary MadTroopers.
      </p>
      <p style={{ marginBottom: "20px" }}>
        The MadTrooper NFT drop concluded recently, and was just the first
        milestone on an extensive roadmap. Several signature MadTrooper 360° VR
        NFTs have since been launched, and more are in the works. The{" "}
        <a
          href="https://shop.madtrooper.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          MadTrooper merchandise store
        </a>{" "}
        also launched recently, and was presented at some of the world’s largest
        crypto conferences including NFT NYC and Solana Breakpoint in Lisbon.
      </p>
      <p style={{ marginBottom: "20px" }}>
        And most importantly, the much-awaited{" "}
        <a
          href="https://youtu.be/kZP7cyq-pgY"
          target="_blank"
          rel="noopener noreferrer"
        >
          MadTrooper game
        </a>{" "}
        is currently undergoing beta testing and will go live soon! Once the
        game launches, we are in talks to do a MadTrooper IGO with some of the
        largest exchanges who are very keen to partner with us to create a
        marketplace for in-game weapons, skins, and other collectibles usable
        within the MadTrooper game.
      </p>
      <p className="blog-content-heading">Upcoming IPs</p>
      <p style={{ marginBottom: "20px" }}>
        Beyond MadTrooper, we own several other major IPs which are now going
        viral and creating their own presence across the Web3, NFT, and
        blockchain gaming space, including content that is compatible with 360°
        VR and Oculus. We will be launching a lot more content related to these
        over the coming year. Here are some of them:
      </p>
      <ol type="1">
        <li>
          SwapTrooper <br />
          <a
            href="https://youtu.be/adIoISQaUyI"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Holotraining
          </a>
          <br />
          <a
            href="https://youtu.be/NvxMihRtT6w"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Commander-in-Chief
          </a>
          <br />
          <a
            href="https://youtu.be/s8pqj876DTw"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Space Farer
          </a>
        </li>
        <li>
          SwapDrone
          <br />
          <a
            href="https://youtu.be/lCnV52WOrYA"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Test Flight
          </a>
          <br />
          <a
            href="https://youtu.be/adIoISQaUyI"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Holotraining
          </a>
          <br />
          <a
            href="https://youtu.be/YSe3hFQg4vA"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Planet Niflheim
          </a>
        </li>
        <li>
          AVA
          <br />
          <a
            href="https://youtu.be/0zwi4XxzGkM"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Alternate Universe
          </a>
          <br />
          <a
            href="https://youtu.be/Duvl9xT7Ix8"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Ava in the MadTrooper base
          </a>
          <br />
          <a
            href="https://youtu.be/xTfflnM2nDU"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Galactic war
          </a>
        </li>
        <li>
          ABDRA-3492 <br />
          <a
            href="https://youtu.be/FPSmPsyPj5c"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Strong, Valiant, Fearless
          </a>
          <br />
          <a
            href="https://youtu.be/XXcUk8QDiz0"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            Overwatch
          </a>
        </li>
        <li>
          EDDA Punks
          <br />
          <a
            href="https://youtu.be/98_4OLEiDP8"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            EDDA Punk #1
          </a>
          <br />
          <a
            href="https://youtube.com/shorts/bF5Z2XT4B4E?feature=share"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            EDDA Punk #1.2
          </a>
        </li>
        <li>
          Yrneha, Shaua, Taryz <br />
          <a
            href="https://youtu.be/W46aGboFPB8"
            target="_blank"
            rel="noopener noreferrer"
            className="blog-link-list"
          >
            MadTrooper Alliance
          </a>
        </li>
      </ol>
      <p className="blog-content-heading-link">
        <a
          href="https://eddaverse.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          EDDAVerse
        </a>
      </p>
      <p style={{ marginBottom: "20px" }}>
        EDDAVerse is a turnkey Web3 partner and enabler for some of the world’s
        largest companies, foundations, brands, and celebrities, including movie
        stars, actors, and athletes. Under the EDDAVerse vertical, we have been
        partnering with these high-profile clients to help them enter Web3 by
        launching products and services such as NFT drops, social tokens,
        metaverse platforms, and blockchain games, and assisting them in
        building their Web3 brand and communities. With EDDAVerse, we are also
        utilizing our Web3 development experience and expertise to launch and
        expand our own IPs such as MadTrooper and the others we showed glimpses
        of above.
      </p>
      <p className="blog-content-heading">The road ahead</p>
      <p style={{ marginBottom: "20px" }}>
        In just one year from the launch, the EDDASwap ecosystem now has
        platforms in every leading vertical in the industry, including NFTs,
        DeFi, and the metaverse, across all major blockchains including
        Ethereum, Solana, Polygon, and Binance Smart Chain.
      </p>
      <p style={{ marginBottom: "20px" }}>
        And we have exciting developments in the works for the coming months,
        especially with the upcoming launches related to EDDAVerse and our new
        IPs. The team is heads-down building non-stop, continuing to improve and
        fine tune each of the existing platforms, and creating the next
        additions to the ecosystem. For users that are new and interested to
        participate in everything that EDDASwap is doing, the best way is to
        obtain some EDDA tokens from Uniswap, and start staking, farming NFTs 
        {/* trading on the DEX,  */}
        and exploring all that the ecosystem has to offer!
      </p>
    </>
  ),
};
