import MetaTags from "react-meta-tags";

export default {
  title: "AMA with the CEO – Mehul (Mike) Patel",
  author: "EDDASwap",
  url: "/blog/ama-with-ceo-227",
  key: "ama-with-ceo-227",
  date: "Friday, July 22nd, 2022",
  thumbnail:
    "https://edda-cdn.fra1.digitaloceanspaces.com/eddaswap/blog/ama.jpeg",
  content: (
    <>
      <MetaTags>
        <meta
          property="og:description"
          content="Join EDDASwap's CEO, Mehul Patel, in this AMA recap as he unveils the team's accomplishments, MadTrooper Blockchain Game, and insights into EDDAVerse. Discover future plans, Coinbase interactions, EDDA's global event participation and more! "
        />
        <meta
          name="description"
          content="Join EDDASwap's CEO, Mehul Patel, in this AMA recap as he unveils the team's accomplishments, MadTrooper Blockchain Game, and insights into EDDAVerse. Discover future plans, Coinbase interactions, EDDA's global event participation and more! "
        />
      </MetaTags>
      <p>
        The CEO spoke about building in the bear market. All the recent
        accomplishments of the entire team and the launch of the MadTrooper
        Blockchain Game
      </p>
      <p>
        A sneak peek into EDDAVerse’s second ad was shown to the entire
        community that joined in live. This ad is dedicated to “The most
        engaging characters in Web3”
      </p>
      <p>
        The concept of EDDAVerse was explained very simply and it boiled down to
        two aspects:
      </p>
      <p>
        EDDAVerse – Home to the most engaging characters in Web3 such as The
        MadTrooper, ABDRA-3492, SwapTrooper, AVA, EDDA Punks and more!
      </p>
      <p>
        EDDAVerse – Providing a turnkey, end-to-end solution for brands and
        celebrities to enter Web3
      </p>
      <ol>
        <li>
          <p>
            When can we expect an updated roadmap for EDDASwap, outlining the
            new use case for the Edda token?
          </p>
          <p style={{ fontWeight: "500" }}>
            Tokens without use cases have seen soaring prices and tokens with
            use cases have seen the worst crashes. While use-cases are
            important, the hype, the engagement and the community are more
            important. New collectibles are being added to the AG staking which
            will be a set of unique magnets. Additionally, in all that we are
            doing for EDDAVerse, EDDA holders will continue to directly benefit
            from the same!
          </p>
        </li>
        <li>
          <p>
            What is the roadmap and plans? Any exchange listings and NFT-NYC or
            NFT-LA type events the team is holding?
          </p>
          <p style={{ fontWeight: "500" }}>
            We cannot disclose too much information publicly about listings. We
            have answered 300+ detailed questions of Coinbase and have been
            cleared for asset custody.
          </p>
          <p style={{ fontWeight: "500" }}>
            We did attend NFT NYC, Solana breakpoint Lisbon, Belgrade, and now
            will be going to Krakow too. Additionally, several other conferences
            have been attended by our team and we share this often on our social
            media. We have attended events in over 8 countries including the
            World Economic Forum in Davos and NFT Day in Paris and are looking
            to attend more conferences in South-East Asia and the middle east
            from now on.
          </p>
        </li>
        <li>
          <p>
            Discussions about Coinbase should be done in detail and in a way
            that gives investors’ confidence. What do they want? how much can we
            afford? Is it really possible to enter Coinbase with such a low
            market cap and volume?
          </p>
          <p style={{ fontWeight: "500" }}>
            This cannot be discussed in great detail publicly due to Coinbase’s
            policies. But yes, this is possible.
          </p>
        </li>
      </ol>
      <p>
        The CEO concluded by saying that while we talk to VCs and clients we
        continue to BUIDL and as this AMA was brief we can have another AMA much
        sooner!
      </p>
      <p>Thank you.</p>
    </>
  ),
  shortDesc:
    "Join EDDASwap's CEO, Mehul Patel, in this AMA recap as he unveils the team's accomplishments, MadTrooper Blockchain Game, and insights into EDDAVerse. Discover future plans, Coinbase interactions, EDDA's global event participation and more!",
};
