// eslint-disable-next-line import/no-anonymous-default-export
import React, { useState } from "react";
import MetaTags from "react-meta-tags";

const Content = () => {
  const [language, setLanguage] = useState("english");

  return (
    <>
      <MetaTags>
        <meta
          property="og:description"
          content="The recent AMA recap from EDDASwap. Learn about upcoming partnerships, EDDAverse's web3 business growth, cross-chain integrations, MadTrooper game developments, and much more."
        />
        <meta
          name="description"
          content="The recent AMA recap from EDDASwap. Learn about upcoming partnerships, EDDAverse's web3 business growth, cross-chain integrations, MadTrooper game developments, and much more."
        />
      </MetaTags>
      <img
        src="https://edda-cdn.fra1.digitaloceanspaces.com/eddaswap/blog/ama.jpeg"
        alt=""
        style={{ marginBottom: "80px", width: "100%" }}
      />
      <div style={{ width: "100%", display: "flex", marginBottom: "40px" }}>
        <div
          onClick={() => setLanguage("english")}
          style={{
            padding: "8px 12px",
            borderRadius: "16px",
            backgroundColor: language === "english" ? "#e6007a" : "transparent",
            color: language === "english" ? "#fff" : "#000",
            opacity: language === "english" ? 1 : 0.5,
            fontSize: "24px",
            lineHeight: "36px",
            fontWeight: "700",
            marginRight: "20px",
            textAlign: "center",
          }}
        >
          English
        </div>
        <div
          onClick={() => setLanguage("spanish")}
          style={{
            padding: "8px 12px",
            borderRadius: "16px",
            backgroundColor: language === "spanish" ? "#e6007a" : "transparent",
            color: language === "spanish" ? "#fff" : "#000",
            opacity: language === "spanish" ? 1 : 0.5,
            fontSize: "24px",
            lineHeight: "36px",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Spanish
        </div>
      </div>
      {language === "english" ? (
        <>
          <p className="blog-content-heading">
            Celebrity updates - EDDASwap has advanced conversations with:
          </p>
          <ul>
            <li>
              {" "}
              <p>
                Several renowned{" "}
                <span style={{ fontWeight: "bold" }}>Bollywood Stars</span> who
                cannot be named at the current stage, who are deeply interested
                in EDDASwap and will be signing agreements soon [06:22]
              </p>
            </li>
            <li>
              <p>
                Internationally known tennis player{" "}
                <span style={{ fontWeight: "bold" }}>Novak Djokovic</span> and
                his entire team have met with EDDASwap in Belgrade, they are
                interested in NFTs and the conversation also involves showcasing
                the work of his Foundation [08:27]
              </p>
            </li>
          </ul>

          <p className="blog-content-heading">NBA Players:</p>
          <ul>
            <li>
              <p>
                <span style={{ fontWeight: "bold" }}>Kobe Bryant</span>´s
                friends and family who are interested in EDDASwap´s capacity to
                create content, social tokens, auctions, DAOs and infrastructure
                (smart contracts) to manage high valued collectible items
                (extremely rare sneakers, motivational messages) and other
                family items. Two world top marketplaces have offered
                partnerships and the family has rejected them. They are
                currently in advanced conversations with EDDASwap [11:00]
              </p>
            </li>
            <li>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Other NBA players based in L.A.
                </span>{" "}
                are interested in marketing goods owned by Kobe and produce
                NFTs, including gold medal winner women players [14:25]
              </p>
            </li>
            <li>
              <p>
                EDDASwap has been required to engage in several conversations
                with representatives, law firms, marketing agencies, brands and
                artists who are directly involved in the public presence of the
                celebrities and are concerned about the success, the legality
                and the potential long-term results, which adds other layers of
                complexities to the negotiations [15:56] and [16:49]
              </p>
            </li>
          </ul>
          <p className="blog-content-heading">Luxyry items:</p>
          <ul>
            <li>
              <p>
                EDDASwap has advanced conversations with a{" "}
                <span style={{ fontWeight: "bold" }}>Swiss Watch</span>{" "}
                manufacturer (directly involved in designing the watch Hublot
                Big Bang) Mr. they are interested in designing exclusive watches
                for EDDA and MadTrooper. The community will be able to earn
                watches through staking, accruing Samurai points or gaming
                (still to be decided) [06:55]
              </p>
            </li>
            <li>
              <p>
                <span style={{ fontWeight: "bold" }}>Automotive brands</span> –
                top 3 world brands will be signing a contract with EDDASwap in
                the next few weeks and the agreement involves key technical web3
                capacities such as content creation, Metaverse, NFTs, smart
                contracts - IaS (Infrastructure-as-Service) and DAOs [06:30]
              </p>
            </li>
            <li>
              <p>
                <span style={{ fontWeight: "bold" }}>Art Galleries</span> in New
                York and London – who have been in the art business for over 50
                years are looking into partnering with EDDASwap to set up NFT
                Galleries and sell NFTs to their art collector customers. They
                like the quality of EDDA NFTs and are interested in selecting
                high quality aesthetics NFTs [56:40]
              </p>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: "bold" }}>EDDAverse</span> will encompass
            all the activities around the management of agreements and
            consulting services with celebrities and companies for content
            creation, DAOs, provision of smart contract - IaS
            (Infrastructure-as-Service), hosting of marketplace activities for
            NFTs and crypto assets [12:45]
          </p>
          <p>
            From EDDAverse’s web3 business growth the following will derive:
          </p>
          <ul>
            <li>
              <p>
                25% of all EDDA revenue will be shared to do buy-backs of EDDA
                tokens, as committed in the roadmap [13:13]
              </p>
            </li>
            <li>
              <p>EDDAverse will conduct Airdrops to the community [13:32]</p>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: "bold" }}>The world´s biggest CEX</span>{" "}
            is interested in MadTrooper, they are currently auditing and
            conducting due diligence to the different options the project
            offers. EDDASwap believes their interest may involve IGO (Initial
            Gaming Offering), in-game-skins, weapons, and other features. More
            information will be available at the end of Q1 [23:30]
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              MadTrooper Game prototyping v1
            </span>{" "}
            – EDDASwap has hired a new game development team (8 people) and they
            will soon be introduced to the community through the social media
            platforms. EDDASwap has a long-term vision on gaming which also
            encompasses celebrity gamification support [24:24]
          </p>
          <p className="blog-content-heading">
            EDDASwap Social Media Activity migration [27:00]
          </p>
          <ul>
            <li>
              <p>
                A team of 3 dedicated social media managers are constantly
                posting content in Twitter, Instagram, TikTok, etc., and
                positioning EDDA with SEO strategies for improved searching
                results [29:40]
              </p>
            </li>
            <li>
              <p>
                More than 18 NFTs have been posted in the last 45 days and the
                links are constantly being share in social media [30:05]
              </p>
            </li>
            <li>
              <p>
                Youtube Influencers - EDDASwap has shifted gears from contacting
                top influencers into engaging with mid-sized influencers to
                achieve more organic showcasing [30:48]
              </p>
            </li>
            <li>
              <p>
                Twitter´s presence has been up scaled to include EDDA Gifs and
                images in the posting field [43:30]
              </p>
            </li>
          </ul>
          <p className="blog-content-heading">Cross-chain Integrations:</p>
          <ul>
            <li>
              <p>
                Holders of $UST-LUNA, $HEX and $LOOKS will be able to buy NFTs
                in EDDASwap within the next 5 days [32:10]
              </p>
            </li>
            <li>
              <p>
                Solana staking mechanism has been live for several months.
                Staking is fundamental to connect many other features that are
                cost effective. [47:25]
              </p>
            </li>
            <li>
              <p>
                Cross-integration and bridging tokens requires a lot of time and
                financial investment and it affects all retail customers and
                emerging projects.
              </p>
            </li>
          </ul>
          <p className="blog-content-heading">Upcoming perks and benefits:</p>
          <ul>
            <li>
              <p>
                Before the end of Q1 EDDASwap will bring the Limited Editions
                Swiss Watch, 1oz Silver Coins from reputed Spanish company.
                Anyone holding MadTrooper NFTs and is staking them will have
                many benefits [48:00]
              </p>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Acknowledgement of challenges and work in progress
            </span>{" "}
            - The team understands the challenges with ETH chain´s cost
            structure. EDDASwap is looking into multiple L1 solutions and the
            first most efficient option seems to be Fantom (FTM) due to its high
            liquidity, level of development and the efficient cost per activity.
            The only alternative would be to migrate and re-build all dAPPS,
            re-do the native token with a bridge (without changing the token
            supply) and deploy into Fantom [50:50]
          </p>
          <p>
            Mike referred extensively about the commitment that EDDASwap has
            with building an ecosystem that will exist for many years and will
            offer products that no other company in the world can match.
          </p>
          <p>
            The team believes it is important to dedicate some more resources
            into capturing achievements and news to create infographics,
            factsheets and other tools that will be shared with the community
            regularly [40:23]
          </p>
        </>
      ) : (
        <>
          <p className="blog-content-heading">
            Actualizaciones sobre el acercamiento a celebridades. EDDASwap tiene
            conversaciones avanzadas con:
          </p>
          <ul>
            <li>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Varias estrellas de cine de Bollywood
                </span>
                , que no pueden ser nombradas en este momento, las celebridades
                están profundamente interesadas en el trabajo de EDDASwap y
                pronto firmarán acuerdos [06:22]
              </p>
            </li>
            <li>
              <p>
                El reconocido tenista{" "}
                <span style={{ fontWeight: "bold" }}>Novak Djokovic</span> y
                todo su equipo se han reunido con EDDASwap en Belgrado, para
                expresar su interés en los NFTs y la conversación también
                implica promover el trabajo de la Fundación Djokovic [08:27]
              </p>
            </li>
          </ul>
          <p className="blog-content-heading">Jugadores de la NBA:</p>
          <ul>
            <li>
              <p>
                Los amigos y familiares de{" "}
                <span style={{ fontWeight: "bold" }}>Kobe Bryant</span> están
                interesados en la capacidad de EDDASwap para crear contenido,
                tokens sociales, subastas, DAO e infraestructura (contratos
                inteligentes) para administrar artículos coleccionables de alto
                valor (tenis extremadamente raros, mensajes motivacionales
                escritos por KB) y otros artículos familiares. Dos de los
                principales cryptomercados del mundo han ofrecido comprar o
                asociarse, pero la familia les ha rechazado. Actualmente están
                en conversaciones avanzadas con EDDASwap [11:00]
              </p>
            </li>
            <li>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Otros jugadores de la NBA
                </span>{" "}
                con sede en Los Ángeles, incluidas jugadoras ganadoras de
                medalla de oro de la WNBA, están interesados en comercializar
                bienes propiedad de Kobe y producir NFTs, [14:25]
              </p>
            </li>

            <li>
              <p>
                EDDASwap ha participado en varias conversaciones con
                representantes, bufetes de abogados, agencias de marketing,
                marcas y artistas que están directamente involucrados en la
                presencia pública de las celebridades mencionadas y quienes
                están preocupados por el éxito, la legalidad y los posibles
                resultados a largo plazo, lo que agrega otros niveles de
                complejidad a las negociaciones [15:56] y [16:49]
              </p>
            </li>
          </ul>
          <p className="blog-content-heading">Artículos de lujo:</p>
          <ul>
            <li>
              <p>
                EDDASwap tiene conversaciones avanzadas con un{" "}
                <span style={{ fontWeight: "bold" }}>
                  fabricante de relojes suizo{" "}
                </span>{" "}
                (directamente involucrado en el diseño del reloj Hublot Big
                Bang) la empresa está interesada en diseñar relojes exclusivos
                para EDDA y MadTrooper. La comunidad podrá ganar relojes a
                través del staking, la acumulación de puntos Samurai o el juego
                de MadTrooper (detalles aún por definirse) [06:55]
              </p>
            </li>
            <li>
              <p>
                <span style={{ fontWeight: "bold" }}>Marcas automotrices:</span>{" "}
                3 principales marcas mundiales firmarán un contrato con EDDASwap
                en las próximas semanas y el acuerdo involucra capacidades
                técnicas clave de web3 como creación de contenido, Metaverse,
                NFTs, contratos inteligentes - IaS (Infraestructura como
                servicio) y DAO [06:30]
              </p>
            </li>
            <li>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Galerías de arte en Nueva York y Londres,
                </span>{" "}
                que han estado en el negocio de la venta de arte durante más 50
                años buscan asociarse con EDDASwap para establecer Galerías NFTs
                y vender NFTs a sus clientes coleccionistas de arte. Han
                reconocido la calidad de los NFTs de EDDA y están interesados en
                seleccionar NFTs de alta calidad estética [56:40]
              </p>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: "bold" }}>EDDAverse</span> congregará
            todas las actividades en torno a la gestión de acuerdos y servicios
            de consultoría con celebridades y empresas para la creación de
            contenido, DAO, provisión de contratos inteligentes - IaS
            (Infrastructure-as-Service), actividades de compra y venta de NFTs y
            criptoactivos [12:45]
          </p>
          <p>
            Del crecimiento del negocio web3 de EDDAverse se derivará lo
            siguiente:
          </p>
          <ul>
            <li>
              <p>
                El 25% de todos los ingresos de EDDA se compartirán para
                realizar re-compras de tokens EDDA, tal como fue acordado en el
                roadmap [13:13]
              </p>
            </li>
            <li>
              <p>EDDAverse realizará Airdrops a la comunidad [13:32]</p>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: "bold" }}>
              El CEX más grande del mundo
            </span>{" "}
            está interesado en MadTrooper, actualmente están auditando el
            proyecto y realizando la debida diligencia a las diferentes opciones
            que ofrece. EDDASwap cree que su interés puede involucrar IGO
            (Initial Gaming Offering - Oferta Inicial de Juego), la producción
            de máscaras, armas y otras características. Más información estará
            disponible al final del Q1 [23:30]
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Construcción del Prototipo del Juego MadTrooper v1
            </span>{" "}
            – EDDASwap ha contratado a un nuevo equipo de desarrolladores de
            juegos (8 personas) quienes pronto serán presentados a la comunidad
            a través de las plataformas de redes sociales. EDDASwap tiene una
            visión a largo plazo sobre los juegos que también abarca el apoyo a
            la gamification de celebridades [24:24]
          </p>
          <p className="blog-content-heading">
            Evolución de actividades en redes sociales de EDDASwap [27:00]
          </p>
          <ul>
            <li>
              <p>
                Un equipo de 3 gestoras están dedicadas a las redes sociales,
                constantemente publican contenido en Twitter, Instagram, TikTok,
                etc., y posicionan EDDA con estrategias de SEO para mejorar los
                resultados de búsqueda [29:40]
              </p>
            </li>
            <li>
              <p>
                Se han publicado más de 18 NFT en los últimos 45 días y los
                enlaces se comparten asiduamente en las redes sociales [30:05]
              </p>
            </li>
            <li>
              <p>
                Youtube Influencers - EDDASwap dejó de contactar a los
                principales influencers e inició conversaciones con influencers
                de tamaño medio para lograr un compromiso más orgánico [30:48]
              </p>
            </li>
            <li>
              <p>
                La presencia de Twitter se ha ampliado con la inclusión de GIFs
                e imágenes de EDDA en el campo de publicación [43:30]
              </p>
            </li>
          </ul>
          <p className="blog-content-heading">Integraciones entre cadenas:</p>
          <ul>
            <li>
              <p>
                Los poseedores de $UST-LUNA, $HEX y $LOOKS podrán comprar NFTs
                en EDDASwap en los próximos 5 días [32:10]
              </p>
            </li>
            <li>
              <p>
                El mecanismo de NFT staking en solana ha estado activo durante
                varios meses. El staking es fundamental para conectar muchas
                otras características que son eficientes para otras
                integraciones [47:25]
              </p>
            </li>
            <li>
              <p>
                La integración cruzada (cross-chain) y los puentes (bridges)
                requieren mucho tiempo e inversión financiera y afecta a todos
                los clientes minoristas y proyectos emergentes.
              </p>
            </li>
          </ul>
          <p className="blog-content-heading">
            Próximos beneficios y ventajas:
          </p>
          <ul>
            <li>
              <p>
                Antes de que finalice el Q1 2022 EDDASwap empezará la
                distribución de las ediciones limitadas de los relojes suizos y
                de las monedas de plata de 1oz de una reconocida empresa
                española. Todas las personas que tengan NFTs de MadTrooper y los
                mantenga en staking obtendrán beneficios [48:00]
              </p>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Reconocimiento de los desafíos y el trabajo en progreso
            </span>{" "}
            - El equipo comprende los desafíos que presenta la estructura de
            costos (fees) de la cadena ETH. EDDASwap está buscando múltiples
            soluciones L1 y la primera opción más eficiente parece ser Fantom
            (FTM) debido a su alta liquidez, nivel de desarrollo y el costo
            eficiente por actividad. La única alternativa sería migrar y volver
            a construir todas las dAPPS, re-hacer el token nativo con un puente
            (sin cambiar el suministro total de tokens) e implementarlo en
            Fantom (aún por definirse) [50:50]
          </p>
          <p>
            Mike comentó ampliamente el compromiso que EDDASwap tiene con la
            construcción de un ecosistema que permanecerá activo por muchos años
            y ofrecerá productos que ninguna otra empresa en el mundo pueda
            igualar.
          </p>
          <p>
            El equipo considera que es importante dedicar más recursos a
            capturar retroalimentación de la comunidad, comunicar los logros,
            las noticias y crear infografías, hojas informativas y otras
            herramientas que serán compartidas con la comunidad regularmente
            [40:23]
          </p>
        </>
      )}
    </>
  );
};
export default {
  title: "AMA Recap",
  author: "EDDASwap",
  url: "/blog/ama-recap",
  key: "ama-recap",
  date: "January 20th, 2022",
  thumbnail:
    "https://edda-cdn.fra1.digitaloceanspaces.com/eddaswap/blog/ama.jpeg",
  content: <Content />,
  shortDesc:
    "The recent AMA recap from EDDASwap. Learn about upcoming partnerships, EDDAverse's web3 business growth, cross-chain integrations, MadTrooper game developments, and much more.",
};
