// eslint-disable-next-line import/no-anonymous-default-export
export default {
  title:
    "Presenting EDDASwap’s Staking Platform 2.0 – Staking for Physical Collectibles!",
  author: "EDDASwap",
  url: "/blog/staking",
  key: "staking",
  date: "December 28th",
  thumbnail:
    "https://edda-cdn.fra1.digitaloceanspaces.com/eddaswap/blog/staking.jpeg",
  content: (
    <>
      <img
        src="https://edda-cdn.fra1.digitaloceanspaces.com/eddaswap/blog/staking.jpeg"
        alt=""
        style={{ marginBottom: "80px", width: "100%" }}
      />
      <p style={{ marginBottom: "20px" }}>
        A few days ago, we unveiled the first glimpse of the special EDDASwap
        branded silver coins that are the first ever physical collectibles from
        EDDASwap! The reveal was received with a lot of excitement from the
        community, and the details on how you can get your hands on them are
        finally here.
      </p>
      <p style={{ marginBottom: "80px" }}>
        EDDASwap’s Staking Platform 2.0 has been revamped with a never before
        seen twist: staking for physical collectibles! The “EDDASwap” and
        “Gandhi” pure silver (.999) coins are just the first of many ultra
        high-end, luxury collectibles that will be made available to stakers
        through the new platform.
      </p>
      <p className="blog-content-heading">
        Here’s a step-by-step guide to using the{" "}
        <a href="https://app.eddaswap.com/ag">Staking Platform 2.0 </a>:
      </p>
      <p style={{ marginBottom: "20px" }}>
        1. The Staking Platform will continue to have two unique staking pools,
        namely the EDDA and EDDA LP pools.
      </p>
      <p style={{ marginBottom: "20px" }}>
        2. If you don’t already have EDDA tokens, you can buy your desired
        amount of EDDA tokens from Uniswap. To obtain EDDA LP tokens, you will
        need to first provide liquidity to the EDDA-ETH trading pair.
      </p>
      <p style={{ marginBottom: "20px" }}>
        3. Next, head to the Staking Platform, connect your wallet, and stake
        your tokens.
      </p>
      <p style={{ marginBottom: "20px" }}>
        4. Once the tokens are staked, they will start generating “Ag” points on
        a daily basis. This point reward system is similar to the NFT Platform’s
        Samurai points.
      </p>
      <p style={{ marginBottom: "20px" }}>
        5. Each EDDA token staked will generate 5 Ag every 24 hours, and each
        EDDA LP token staked will generate 30 Ag every 24 hours.
      </p>
      <p style={{ marginBottom: "20px" }}>
        6. Once you have accumulated enough Ag, you can redeem them for one of
        the coins. Upon redemption, your wallet will receive a special NFT of
        the coin.
      </p>
      <p style={{ marginBottom: "20px" }}>
        7. Users will then have two options depending on their preference. You
        can hold the NFT of the coin purely as a digital collectible (or
        alternatively, sell the NFT on a secondary marketplace such as OpenSea).
      </p>
      <p style={{ marginBottom: "80px" }}>
        8. However, those users that wish to receive the actual physical coins
        will have the option of claiming their physical coins by burning the
        NFT. These users will then be prompted to provide their delivery
        address, and the physical coin will be shipped to them by EDDASwap for
        free. Each coin is loaded with 0.02 ETH as well!
      </p>
      <p className="blog-content-heading">More about the Silver Coins</p>
      <p style={{ marginBottom: "20px" }}>
        The coins are made of pure silver, and there are two unique designs:
        “EDDASwap” and “Gandhi” coins. Both are limited edition designs, and
        there are only going to be 40 of each coin. And in true EDDASwap style,
        beyond the luxurious exterior and design, there is also unique
        functionality, because the coins are also physical ETH wallets!
      </p>
      <p style={{ marginBottom: "20px" }}>
        Etched on each coin will be a hologram with a private key to access the
        corresponding ETH wallet. Each wallet will be pre-loaded with 0.02 ETH
        as a bonus. Like any other wallet, you will be able to send and receive
        ERC-20 tokens, NFTs, and everything else. And that’s not all, because
        these wallets will qualify for several special NFT drops and giveaways
        with EDDASwap’s partners in the future!
      </p>
    </>
  ),
  shortDesc:
    "A few days ago, we unveiled the first glimpse of the special EDDASwap branded silver coins that are the first ever physical collectibles from EDDASwap! The reveal was received with a lot of excitement from the community, and the details on how you can get your hands on them are finally here.",
};
