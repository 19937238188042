export const SOCIALS = [
  { id: 1, image: "/header/x.svg", link: "https://twitter.com/EDDASwap" },
  {
    id: 8,
    image: "/header/instagram.svg",
    link: "  https://www.instagram.com/eddaverse.co/",
  },
  // { id: 2, image: "/header/medium.svg", link: "https://eddaswap.medium.com/" },
  { id: 3, image: "/header/telegram.svg", link: "https://t.me/EDDASwap" },

  {
    id: 4,
    image: "/header/youtube.svg",
    link: "https://www.youtube.com/c/EDDAVerse/videos",
  },
  {
    id: 5,
    image: "/header/github.svg",
    link: "https://github.com/EddaSwap/EDDA",
  },
  // {
  //   id: 7,
  //   image: "/header/coinmarketcap.svg",
  //   link: "https://coinmarketcap.com/currencies/eddaswap/",
  // },
  {
    id: 6,
    image: "/header/coingecko.svg",
    link: "https://www.coingecko.com/en/coins/eddaswap",
  },
  {
    id: 9,
    image: "/header/dextool.svg",
    link: "https://www.dextools.io/app/uniswap/pair-explorer/0xf44e8ff3bb644479124e691bf572f7813a1bc499",
  },
];

export const ECOSYSTEMS = [
  // { id: 1, href: "https://dex.eddaswap.com/exchange", title: "EDDASwap DEX" },
  { id: 1, href: "https://app.eddaswap.com/nfts", title: "EDDASwap NFT" },
  {
    id: 1,
    href: "https://app.uniswap.org/#/swap?outputCurrency=0xfbbe9b1142c699512545f47937ee6fae0e4b0aa9&use=V2",
    title: "BUY EDDA",
  },
  { id: 1, href: "https://app.eddaswap.com/staking", title: "STAKE EDDA" },
];

export const LITEPAPERS = [
  {
    id: 1,
    name: "EDDASwap Litepaper",
    link: "https://eddaswap.com/EDDASwap-Litepaper-v1.pdf",
  },
];

export const AUDITS = [
  {
    id: 1,
    name: "EDDA Contract",
    link: "https://eddaswap.com/EDDA-Audit.pdf",
  },
  {
    id: 2,
    name: "NFT Platform",
    link: "https://eddaswap.com/EDDA-NFT-Platform-Audit-Cert.pdf",
  },
  {
    id: 3,
    name: "Staking Platform",
    link: "https://eddaswap.com/EDDA-Staking-Contract-Audit.pdf",
  },
  // {
  //   id: 4,
  //   name: "EDDASwap DEX Core",
  //   link: "https://eddaswap.com/EDDASwap-BSC-DEX.pdf",
  // },
  // {
  //   id: 5,
  //   name: "EDDASwap DEX Router",
  //   link: "https://eddaswap.com/EDDASwap-BSC-DEX-2.pdf",
  // },
  // {
  //   id: 6,
  //   name: "EDDASwap DEX Liquidity Lock",
  //   link: "https://eddaswap.com/EDDASwap-BSC-DEX-Liquidity-Lock.pdf",
  // },
];
