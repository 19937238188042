import React, { useState } from "react";
import Fade from "react-reveal/Fade";

import "./index.css";

const Ecosystem = () => {
  const [expand, setExpand] = useState(false);
  const onToggleExpand = () => setExpand(!expand);
  return (
    <div className="eco-container" id="ecosystem">
      <div className="ecosystem-title">EDDASwap Ecosystem</div>
      <div className="ecosystem-wrapper">
        <div className="ecosystem-item eco-nft">
          <Fade bottom>
            <div className="ecosystem-item-title">
              01. NON-FUNGIBLE TOKENS (NFT)
            </div>
          </Fade>
          <Fade bottom delay="500">
            <div className="ecosystem-description">
              {!expand && (
                <>
                  <p className="">
                    Our <span>NFT platform</span> is live since March 2021.
                    EDDASwap has partnered with leading global artists, 3D
                    motion graphic designers and producers to create a specially
                    curated NFT collection which has been in production since Q2
                    2020. Our Ultra Rare NFTs have gone viral globally and have
                    been shared, retweeted and posted by Cameron Winklevoss,
                    Tyler Winklevoss, Jason A Williams and many global
                    influencers. EDDA token holders and stakers have exclusive
                    access to EDDASwap’s NFT collection created by well-known
                    artists including&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=52"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      OZ
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=81"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cyrus James Khan
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=13"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Primal Cypher
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=63"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Marnik
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=71"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Yana Khvan
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=78"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BatMax
                    </a>
                    ,&nbsp; ...
                  </p>
                  <p
                    onClick={onToggleExpand}
                    style={{ fontWeight: "700", marginTop: "32px" }}
                  >
                    Expand <img src="/ecosystem/expand.svg" />
                  </p>
                </>
              )}
              {expand && (
                <>
                  <p className="ecosystem-description">
                    Our NFT platform is live since March 2021. EDDASwap has
                    partnered with leading global artists, 3D motion graphic
                    designers and producers to create a specially curated NFT
                    collection which has been in production since Q2 2020. Our
                    Ultra Rare NFTs have gone viral globally and have been
                    shared, retweeted and posted by Cameron Winklevoss, Tyler
                    Winklevoss, Jason A Williams and many global influencers.
                    EDDA token holders and stakers have exclusive access to
                    EDDASwap’s NFT collection created by well-known artists
                    including&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=52"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      OZ
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=81"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cyrus James Khan
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=13"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Primal Cypher
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=63"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Marnik
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=71"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Yana Khvan
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=78"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BatMax
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=67"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Space Yacht Labs
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=74"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      D3sert Punk
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=30"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LayerInsane
                    </a>
                    ,&nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=78"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Rafi Amar
                    </a>
                    &nbsp;
                    <a
                      className="ecosystem-artist"
                      href="https://app.eddaswap.com/nft-detail?id=42"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stefan Große Halbuer
                    </a>
                    ,&nbsp; amongst others. Our NFT platform facilitates the
                    representation of unique, real world assets through
                    tokenization on several blockchains such as Etheruem,
                    Polygon and Binance Smart Chain. EDDA token holders are
                    staking their EDDA or EDDA (UNI-V2) LP tokens in order to
                    earn Samurai points. These Samurai points are used to redeem
                    EDDASwap’s exclusive NFTs which are being sold on NFT
                    marketplaces such as OpenSea.
                  </p>
                  <p onClick={onToggleExpand}>
                    Show Less <img src="/ecosystem/expand.svg" />
                  </p>
                </>
              )}
            </div>
            <div>
              <a
                className="ecosystem-btn edda-button"
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.eddaswap.com/nfts"
              >
                EDDASwap NFT
              </a>
              <img
                src="https://edda-cdn.fra1.cdn.digitaloceanspaces.com/eddaswap/public/eddaverse_01_recudeced.webp"
                alt=""
                className="nft-image"
              />
            </div>
          </Fade>
        </div>
        <div className="ecosystem-item eco-nft">
          <Fade bottom>
            <div className="ecosystem-item-title">
              02. PHYSICAL COLLECTIBLES
            </div>
          </Fade>
          <Fade bottom delay="500">
            <div className="ecosystem-description">
              <p className="" style={{ marginBottom: "20px " }}>
                EDDASwap’s Staking Platform 2.0 has been revamped with a never
                before seen twist: staking for physical collectibles! EDDASwap’s
                trailblazing NFTs have established themselves as the most
                sought-after digital works of art in the industry, and now
                EDDASwap has expanded its artistic vision to the world of
                physical collectibles.{" "}
              </p>
              <p style={{ marginBottom: "20px " }}>
                The first EDDASwap branded physical collection is an exquisite,
                limited-edition collection of 80 EDDASwap and Gandhi themed pure
                silver coins that are also functional ETH wallets! Etched on
                each coin will be a hologram with a private key to access the
                corresponding ETH wallet. Each wallet will be pre-loaded with
                0.02 ETH as a bonus. Like any other wallet, you will be able to
                send and receive ERC-20 tokens, NFTs, and everything else.
              </p>
              <p style={{ marginBottom: "32px " }}>
                {" "}
                These silver coins are just the first of many ultra high-end,
                luxury collectibles that will be made available to stakers
                through the new platform, with several additions coming soon,
                including luxury custom-made Swiss watches!
              </p>
              <Fade bottom delay="1000">
                <a
                  className="ecosystem-btn edda-button"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://app.eddaswap.com/ag"
                  style={{ marginBottom: "0px" }}
                >
                  Stake EDDA
                </a>
              </Fade>
            </div>
          </Fade>
          <img
            src="https://edda-cdn.fra1.cdn.digitaloceanspaces.com/eddaswap/public/physical.svg"
            className="physical-img"
          />
        </div>
      </div>
    </div>
  );
};

export default Ecosystem;
