import "./App.css";

import "antd/dist/antd.css";

import HomePage from "./pages/Home";
// import "@fontsource/montserrat";

function handelRightClick(event) {
  event.preventDefault();
}

function App() {
  document.addEventListener('contextmenu', handelRightClick);
  return <HomePage />;
}

export default App;
