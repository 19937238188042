import Header from "../../components/Header/desktop";
import Layout from "../../components/Layout";
import Footer from "../../components/Footer";

import GetInTouch from "../../components/GetInTouch";
import BlogCard from "../../components/BlogCard";

import { useState } from "react";
import classnames from "classnames";

import "./index.css";
import blogs from "../../blogs";

const perRow = 3;

const Arrow = ({ type, active, onClick }) => {
  return (
    <div
      className="arrow-container"
      style={{ backgroundColor: active ? "#e6007a" : "#E5E5E5" }}
      onClick={onClick}
    >
      <div
        className={classnames({
          arrow: true,
          "next-arrow": type === "next",
          "prev-arrow": type === "prev",
          "active-arrow": active,
        })}
      />
    </div>
  );
};

const BlogPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const maxPage = Math.ceil((blogs.length || 0) / perRow);
  return (
    <div
      className="App"
      style={{
        backgroundColor: "#EDEDF2",
        display: "block",
        paddingTop: "20px",
      }}
    >
      <Header />
      <Layout>
        <div className="blogs-title">
          <div className="blogs-title-1">
            EDDA<span style={{ color: "#020313" }}>Swap</span>
          </div>
          <div className="blogs-title-2">Blog</div>
        </div>
        {maxPage > 0 && (
          <>
            <div className="blog-list">
              {blogs.map((blog) => (
                <BlogCard {...blog} />
              ))}
            </div>
            {/* <div className="blogs-navigators">
              <Arrow
                type="prev"
                active={currentPage > 0}
                onClick={() => {
                  const prevPage = currentPage - 1;
                  if (prevPage < 0) {
                    return;
                  } else {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              />
              <div style={{ width: "100px", textAlign: "center" }}>
                Page {currentPage + 1} of {maxPage}
              </div>
              <Arrow
                type="next"
                active={currentPage < maxPage - 1}
                onClick={() => {
                  const nextPage = currentPage + 1;
                  if (nextPage > maxPage - 1) {
                    return;
                  } else {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              />
            </div> */}
          </>
        )}

        <GetInTouch />
        <Footer />
      </Layout>
    </div>
  );
};

export default BlogPage;
