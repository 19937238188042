export const TEAMS = [
  {
    id: 1,
    name: "Mehul (Mike) Patel",
    role: "Founder & Chief Executive Officer",
    twitter: "https://twitter.com/TheMehulPatel",
    linkedin: "https://www.linkedin.com/in/themehulpatel/",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/eddaswap/team/mike.webp",
    about: (
      <>
        <p>
          <b style={{ color: "#E6007A" }}>Mehul (Mike)</b> is a high-impact
          entrepreneur with over 20 years of experience, having previously grown
          and exited several successful Internet ventures from Asia to Silicon
          Valley.
        </p>
        <p>
          He has hosted, moderated and participated in multiple cryptocurrency
          and blockchain panels at the World Economic Forum in Davos,
          Switzerland, for over 3 years. He has also been a keynote speaker and
          panelist at several cryptocurrency conferences.
        </p>
        <p>
          He has previously established a luxury consulting company that worked
          with global brands such as Jaguar, BMW, Moët Hennessy Louis Vuitton
          (LVMH), Audi, and Remy Martin.
        </p>
        <p>
          Circa 2000, he was professionally engaged by NewsCorp Asia to build
          and launch a first of its kind "Wireless Application Protocol (WAP)"
          portal for their Asian youth channel "V". He had also been engaged by
          the Tata Group to actively oversee and improvise their digital
          operations, and authored a Standardisation Document for Tata Motors
          and Tata Group, redefining their digital guidelines.
        </p>
      </>
    ),
    //to style css
    positionTop: 20,
    aboutColor: "white",
  },
  {
    id: 2,
    name: "Viktor Shpak",
    role: "Chief Technology Officer",
    linkedin: "https://www.linkedin.com/in/vshpak/",
    image: "/team/viktor.jpeg",
    about: (
      <>
        <p>
          <b style={{ color: "#E6007A" }}>Viktor</b> is a technology expert and
          entrepreneur with more than 20 years of experience in the IT industry.
          Since 2013, he has been involved in a number of cryptocurrency and
          blockchain projects as a founder, architect, and Tech Lead.
        </p>
        <p>
          He has deep experience in public and enterprise blockchain platforms,
          such as Bitcoin, Ethereum, and IBM Hyperledger. He also advises
          cryptocurrency and blockchain startups across Europe and USA and has
          recently lent his expertise to help develop and scale several DeFi
          protocols.
        </p>
      </>
    ),
    positionTop: 50,
    aboutColor: "white",
  },
  // {
  //   id: 3,
  //   name: "James Tran",
  //   role: "Technical Leader",
  //   linkedin: "https://www.linkedin.com/in/j-tran-152427293/",
  //   image:
  //     "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/eddaswap/team/james.webp",
  //   about: (
  //     <p>
  //       <b style={{ color: "#E6007A" }}>James Tran</b> is a blockchain engineer
  //       with comprehensive knowledge and hands-on experience of blockchain
  //       technology, especially Ethereum and smart contract development.
  //       Researching the technology from 2017, James has built up several
  //       advanced blockchain-based solutions to solve life problems such as
  //       voting, traceability, supply chain, self-sovereign identity. He used to
  //       work at a startup providing secure blockchain infrastructure across
  //       leading Proof-of-Stake protocols and yield solutions for crypto asset
  //       investors, where he gained an expertise in the DeFi industry and related
  //       developments such as DEXs, Automated Market-Making and Yield Farming.
  //       James is also a true believer in the power and influence of NFTs and the
  //       emerging opportunities regarding the same.
  //     </p>
  //   ),
  //   positionTop: 10,
  //   aboutColor: "#020313",
  // },
  // {
  //   id: 4,
  //   name: "Khoi Nguyen",
  //   role: "Full Stack Developer",
  //   twitter: "",
  //   linkedin: "https://www.linkedin.com/in/danhkhoi/",
  //   image:
  //     "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/eddaswap/team/khoi.webp",
  //   about: (
  //     <p>
  //       <b style={{ color: "#E6007A" }}>Khoi</b> has over 4 years of experience
  //       as a full stack software engineer with strong skills in programming with
  //       Go, Javascript, React JS, relational databases, NoSql, docker, AWS,
  //       HTML, CSS and more. He has an expertise in big and high traffic
  //       applications, across a wide variety including e-commerce, payment
  //       gateways and social networking sites. He is also very familiar with
  //       cross-team collaborations and end-users interaction.
  //     </p>
  //   ),
  //   positionTop: 20,
  //   aboutColor: "#020313",
  // },
  // {
  //   id: 5,
  //   name: "Duy Nguyen",
  //   role: "Full Stack Developer",
  //   twitter: "",
  //   linkedin: "https://www.linkedin.com/in/duy-nguyen-206830120/",
  //   image:
  //     "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/eddaswap/team/duy.webp",
  //   about: (
  //     <p>
  //       <b style={{ color: "#E6007A" }}>Duy</b> is a Fullstack Developer with
  //       over 3 years of experience in software development. He has worked along
  //       with several blockchain companies across North America, Europe and Asia.
  //       With an expertise in Node.js, React.js, PostgresSQL and MongoDB. He also
  //       has great experience in building large scale, robust systems and
  //       applications. In addition to the development of blockchain related
  //       applications he also shares a passion for cryptocurrencies as a whole.
  //     </p>
  //   ),
  //   positionTop: 10,
  //   aboutColor: "white",
  // },
  {
    id: 8,
    name: "Veronika Vašková",
    role: "PR and Outreach",
    twitter: "",
    linkedin: "",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/eddaswap/team/vero.webp",
    about: (
      <p>
        <b style={{ color: "#E6007A" }}>Veronika</b> has a vast experience in
        Sales/Customer Services and HR. Dealing with stakeholders and clients on
        a daily basis as well as being a team leader. She has acquired valuable
        communication skills, which have allowed her to be a great team player
        and build strong relationships. Veronika also has broad experience in
        management consultation, team building, professional development,
        strategic implementation, and company collaboration.
      </p>
    ),
    positionTop: 30,
    aboutColor: "#020313",
  },
  {
    id: 9,
    name: "Dina Shevchenko",
    role: "Media and Communications",
    twitter: "",
    linkedin: "",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/eddaswap/team/dina.webp",
    about: (
      <p>
        <b style={{ color: "#E6007A" }}>Dina</b> is a communications specialist.
        She has a vast experience in creating internal and external
        communication strategies, brand promotion, content creation,
        translation, marketing and team management. She is committed to driving
        positive change, building strong relationships and is passionate about
        innovation and personal growth and development. Managing large
        communities and client-bases is one of her specialities while the crypto
        world is her passion.
      </p>
    ),
    positionTop: 30,
    aboutColor: "white",
  },
];
