export const SOCIALS = [
  { id: 1, image: "/intro/x.svg", link: "https://twitter.com/EDDASwap" },
  {
    id: 8,
    image: "/intro/instagram.svg",
    link: "https://www.instagram.com/eddaverse.co/",
  },
  {
    id: 9,
    image: "/intro/dextool.svg",
    link: "https://www.dextools.io/app/uniswap/pair-explorer/0xf44e8ff3bb644479124e691bf572f7813a1bc499",
  },
  // { id: 2, image: "/intro/medium.svg", link: "https://eddaswap.medium.com/" },
  { id: 3, image: "/intro/telegram.svg", link: "https://t.me/EDDASwap" },
  {
    id: 4,
    image: "/intro/youtube.svg",
    link: "https://www.youtube.com/c/EDDAVerse/videos",
  },
  {
    id: 5,
    image: "/intro/github.svg",
    link: "https://github.com/EddaSwap/EDDA",
  },
  // {
  //   id: 7,
  //   image: "/intro/coinmarketcap.svg",
  //   link: "https://coinmarketcap.com/currencies/eddaswap/",
  // },
  {
    id: 6,
    image: "/intro/coingecko.svg",
    link: "https://www.coingecko.com/en/coins/eddaswap",
  },
];
