import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import App from "./App";
import Terms from "./components/Term";
import BlogPage from "./pages/Blog";
import BlogContent from "./pages/BlogContent";

const appRouter = (
  <BrowserRouter>
    <Routes>
      <Route exact path="/blogs" element={<BlogPage />} />
      <Route path="/blog/:post" element={<BlogContent />} />
      <Route exact path="/" element={<App />} />
      <Route exact path="/terms-conditions" element={<Terms />} />
      <Route exact path="*" element={<App />} />
    </Routes>
  </BrowserRouter>
);

export default appRouter;
