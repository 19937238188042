import React from "react";
import { useParams } from "react-router-dom";

import Header from "../../components/Header/desktop";
import Layout from "../../components/Layout";
import Footer from "../../components/Footer";
import GetInTouch from "../../components/GetInTouch";

import "./index.css";

import blogs from "../../blogs";
import Fade from "react-reveal/Fade";

const BlogContent = () => {
  const { post } = useParams();
  if (!post) {
    return <div>Content not found</div>;
  }

  const blog = blogs.find((_blog) => _blog.key === post);

  if (!blog) {
    return <div>Content not found</div>;
  }

  return (
    <div
      className="App"
      style={{
        backgroundColor: "#EDEDF2",
        display: "block",
        paddingTop: "20px",
      }}
    >
      <Header />
      <Layout>
        <Fade>
          <div className="blog-container">
            <div className="blog-heading">
              <Fade delay="300" bottom>
                <div className="blog-title">{blog.title}</div>
              </Fade>
              <Fade delay="600" bottom>
                <div className="blog-subTitle">
                  {blog.date} by{" "}
                  <span className="blog-author">{blog.author}</span>
                </div>
              </Fade>
            </div>
            <Fade delay="1000" bottom>
              <div className="blog-content-container">{blog.content}</div>
            </Fade>
          </div>
        </Fade>
        <GetInTouch />
      </Layout>
      <Footer />
    </div>
  );
};

export default BlogContent;
